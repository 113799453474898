import { Button, TextField, Typography } from "@mui/material";
import { useState } from "react";

const Koti = ({ tiedot, onMuutos }) => {
    const [muokattuNimi, setMuokattuNimi] = useState(null);
    const nimiMuuttui = () => {
        onMuutos({...tiedot, nimi: muokattuNimi});
        setMuokattuNimi(null);
    };
    return (
        <div>
            <Typography padding='1em'>
                Valitse nimi, jolla sinut voidaan tunnistaa pistelistoilta
            </Typography>
            <TextField
                value={muokattuNimi ?? tiedot.nimi ?? ''}
                onChange={(event) => setMuokattuNimi(event.target.value)}
            />
            <br/>
            <Button disabled={!muokattuNimi} onClick={nimiMuuttui}>tallenna nimi</Button>
            {!tiedot.nimi && <Typography padding='1em'>
                Nimi on pakollinen, jotta voit siirtyä eteenpäin
            </Typography>
            }
        </div>
    );
};
export default Koti;