import { Button, Card, CardContent, CardHeader, CircularProgress, Typography } from "@mui/material";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "./App";

const googleProvider = new GoogleAuthProvider();

const kirjauduGoogle = async () => {
    signInWithPopup(auth, googleProvider);     
};

const LogIn = () =>{
    return (
        <Card>
            <CardContent>
                <Typography variant='body1'>
                Tervetuloa urheiluveikkaukseen
                </Typography>
                <Button onClick={kirjauduGoogle}>
                    Kirjaudu Googlella
                </Button>
            </CardContent>
        </Card>
    );
};
export default LogIn;