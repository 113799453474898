import { CircularProgress, List, ListItem, Typography } from "@mui/material";
import { collection, collectionGroup, doc, getDoc, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { firestore } from "./App";

const Tilastot = () => {
    // 1. Tilamuuttujat
    const [kayttajat, setKayttajat] = useState(null);

    // 2. Apufunktiot
    const laskePisteet = (tulokset, veikkaukset, annetutPisteet) => {
        let pisteet = 0;
        tulokset.forEach((doc) => {
            const o = doc.tulos;
            const v = veikkaukset[doc.otsikko];
            if (v && o) {
                o.forEach((a, b) => {
                    if (a === v[b]) {
                        pisteet = pisteet + annetutPisteet;
                    }
                });
            }
        });
        return pisteet;
    };
    const haeKayttajat = async () => {
        const haku = await getDocs(collection(firestore, 'kayttajat'));
        const tiedot = haku.docs.map((doc) => {
            return { ...doc.data(), id: doc.id, pisteet: 0 };
        });
        const lohkot = await getDoc(doc(firestore, 'vaiheet', 'lohko'));
        const neljannesvalierat = await getDoc(doc(firestore, 'vaiheet', 'neljannesvalierat'));
        const puolivalierat = await getDoc(doc(firestore, 'vaiheet', 'puolivalierat'));
        const valierat = await getDoc(doc(firestore, 'vaiheet', 'valierat'));
        const pronssiottelu = await getDoc(doc(firestore, 'vaiheet', 'pronssiottelu'));
        const finaali = await getDoc(doc(firestore, 'vaiheet', 'finaali'));
        const veikkaukset = await getDocs(collectionGroup(firestore, 'veikkaukset'));
        veikkaukset.docs.forEach((doc) => {
            const user = doc.ref.parent.parent.id;
            let oikeat;
            let annetutPisteet;
            if (doc.id === 'lohko') {
                oikeat = lohkot.data().kohteet;
                annetutPisteet = 2;
            } else if (doc.id === 'neljannesvalierat') {
                oikeat = neljannesvalierat.data().kohteet;
                annetutPisteet = 1;
            } else if (doc.id === 'puolivalierat') {
                oikeat = puolivalierat.data().kohteet;
                annetutPisteet = 1;
            } else if (doc.id === 'valierat') {
                oikeat = valierat.data().kohteet;
                annetutPisteet = 1;
            } else if (doc.id === 'pronssiottelu') {
                oikeat = pronssiottelu.data().kohteet;
                annetutPisteet = 3;
            } else if (doc.id === 'finaali') {
                oikeat = finaali.data().kohteet;
                annetutPisteet = 5;
            }  else {
                return;
            }
            const utiedot = tiedot.find((x) => {
                return x.id === user;
            });
            if (utiedot) {
                utiedot.pisteet = utiedot.pisteet + laskePisteet(oikeat, doc.data().veikkaukset, annetutPisteet);
            }
        });
        tiedot.sort((a, b) => {
            const ap = a.pisteet ?? 0;
            const bp = b.pisteet ?? 0;
            if (ap !== bp) {
                return bp-ap;
            }
            return a.nimi.toLocaleLowerCase().localeCompare(b.nimi.toLocaleLowerCase());
        });
        tiedot.forEach((u, i) => {
            if (i > 0 && u.pisteet === tiedot[i-1].pisteet) {
                u.sijoitus = tiedot[i-1].sijoitus;
            } else {
                u.sijoitus = i+1;
            }
        });
        setKayttajat(tiedot);
    };

    // 3. Efektit
    useEffect(() => {
        haeKayttajat();
    }, []);
    // 4. Ulkoasu
    if (!kayttajat) {
        return <CircularProgress />;
    }
    return (
        <div>
            <Typography>

            </Typography>
            <List>
                {kayttajat.map((user, i) => {
                    return (
                        <ListItem key={user.id} secondaryAction={'' + user.pisteet} style={{ backgroundColor: i % 2 ? '#bbb' : 'none' }}>
                            {user.sijoitus}. {user.nimi}
                        </ListItem>
                    );
                })}
            </List>
        </div>
    );
};
export default Tilastot;