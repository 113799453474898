import './App.css';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { initializeApp } from 'firebase/app';
import { CircularProgress } from '@mui/material';
import HomePage from './HomePage';
import LogIn from './LogIn';
import { useEffect } from 'react';

const firebaseConfig = {
  apiKey: 'AIzaSyCgYWFtGUEaSRnL_LEXKLuFrc6XM0eQKF0',
  authDomain: 'urheiluveikkaus.firebaseapp.com',
  projectId: 'urheiluveikkaus',
  storageBucket: 'urheiluveikkaus.appspot.com',
  messagingSenderId: '639877955073',
  appId: '1:639877955073:web:da99a32961fb63bc4c0a00',
  measurementId: 'G-JGK78L7LLM'
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);

const App = () => {
  const [user, loading, error] = useAuthState(auth);
  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);
  if (loading) {
    return (
      <CircularProgress />
    );
  }
  if (user) {
    return (
      <HomePage user={user} />
    );
  }
  return (
    <LogIn error={error} />
  );
};
export default App;
