import { useEffect, useState } from "react";
import { CheckOutlined, CloseOutlined, Home, KeyboardHide, Leaderboard, MoveToInbox, SportsSoccer } from "@mui/icons-material";
import { BottomNavigation, BottomNavigationAction, Typography, Box, Grid, CircularProgress, Button, Icon } from "@mui/material";
import Flag from 'react-world-flags'
import Lippu from "./Lippu";

const Kohde = ({ kohde, valinnat, onValittu, avoinna }) => {
    const [muokkaamassa, setMuokkaamassa] = useState(false);
    const [editoitu, setEditoitu] = useState([]);
    const muokataan = () => {
        setMuokkaamassa(true);
        setEditoitu([]);
    };
    const valitse = (joukkue) => {
        setEditoitu([...editoitu, joukkue]);
    };
    useEffect(() => {
        if (editoitu.length === kohde.joukkueet.length) {
            setMuokkaamassa(false);
            onValittu(editoitu);
        }
    }, [editoitu]);
    useEffect(() => {
        if (!valinnat && avoinna) {
            setMuokkaamassa(true);
        }
    }, []);

    return (
        <Grid item xs={6}>
            <Typography variant='h4'>
                {kohde.otsikko}
            </Typography>
            <ol className="maalista">
                {!muokkaamassa && valinnat && valinnat.map((joukkue, i) =>
                    <li key={joukkue}>
                        <Lippu code={joukkue} />
                        {kohde.tulos[i] === 'TBA' ? 
                         '' : 
                         joukkue === kohde.tulos[i] ? 
                         <CheckOutlined color="success" fontSize="200%"/> : 
                         <CloseOutlined color="error" fontSize="200%"/> }
                    </li>
                )}
                {muokkaamassa && editoitu.map((joukkue) => {
                    return (
                        <li key={joukkue}>
                            <Lippu code={joukkue} />
                        </li>
                    );
                })}
                {muokkaamassa && <li>Valitse</li>}
                {muokkaamassa && kohde.joukkueet.slice(editoitu.length + 1).map((joukkue, i) => {
                    return (
                        <li key={'' + i}/>
                    );
                })}
            </ol>
            {!muokkaamassa && avoinna &&<Button onClick={muokataan} >Vaihda järjestys</Button>}
            {muokkaamassa &&
                <div className="maarivi">
                    {kohde.joukkueet.filter((j) => editoitu.indexOf(j) < 0).map((joukkue) => {
                        return (
                            <div key={joukkue} className="maanappi" onClick={() => valitse(joukkue)}>
                                <Lippu code={joukkue} />
                            </div>
                        );
                    })}
                </div>}
        </Grid>
    );
};
export default Kohde;