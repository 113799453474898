import { useState } from "react";
import Wales from './img/wales.svg';
import England from './img/england.svg';
import Flag from 'react-world-flags';


const Lippu = ({ code }) => {
    if (code === 'WA') {
        return <img src={Wales}/>;
    }
    if (code === 'EN') {
        return <img src={England}/>;
    }
    else {
        return <Flag code={code} />;
    }
};
export default Lippu;