import { useEffect, useState } from "react";
import { Box, Grid, CircularProgress } from "@mui/material";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { firestore } from "./App";
import Kohde from "./Kohde";



const Veikkaus = ({ user }) => {
    // 1. Tilamuuttujat
    const [umpeutuminen, setUmpeutuminen] = useState(null);
    const [lvalinnat, setLValinnat] = useState(null);
    const [lkohteet, setLKohteet] = useState(null);
    const [nvalinnat, setNValinnat] = useState(null);
    const [nkohteet, setNKohteet] = useState(null);
    const [pvalinnat, setPValinnat] = useState(null);
    const [pkohteet, setPKohteet] = useState(null);
    const [vvalinnat, setVValinnat] = useState(null);
    const [vkohteet, setVKohteet] = useState(null);
    const [prvalinnat, setPRValinnat] = useState(null);
    const [prkohteet, setPRKohteet] = useState(null);
    const [fvalinnat, setFValinnat] = useState(null);
    const [fkohteet, setFKohteet] = useState(null);
    // 2. Apufunktiot
    const haeFKohteet = async () => {
        const finaali = await getDoc(doc(firestore, 'vaiheet', 'finaali'));
        try {
            const kayttajan = await getDoc(doc(firestore, 'kayttajat', user.uid, 'veikkaukset', 'finaali'));
            setFValinnat(kayttajan.data().veikkaukset ?? {});
        } catch (e) {
            setFValinnat({});
        }
        setFKohteet(finaali.data().kohteet);
    };
    const vaihdaFValinnat = async (kohde, uusi) => {
        const uudet = {
            ...fvalinnat,
            [kohde.otsikko]: uusi
        };
        setFValinnat(uudet);
        await setDoc(doc(firestore, 'kayttajat', user.uid, 'veikkaukset', 'finaali'), {
            veikkaukset: uudet
        });
    };

    const haePRKohteet = async () => {
        const pronssiottelu = await getDoc(doc(firestore, 'vaiheet', 'pronssiottelu'));
        try {
            const kayttajan = await getDoc(doc(firestore, 'kayttajat', user.uid, 'veikkaukset', 'pronssiottelu'));
            setPRValinnat(kayttajan.data().veikkaukset ?? {});
        } catch (e) {
            setPRValinnat({});
        }
        setPRKohteet(pronssiottelu.data().kohteet);
    };
    const vaihdaPRValinnat = async (kohde, uusi) => {
        const uudet = {
            ...prvalinnat,
            [kohde.otsikko]: uusi
        };
        setPRValinnat(uudet);
        await setDoc(doc(firestore, 'kayttajat', user.uid, 'veikkaukset', 'pronssiottelu'), {
            veikkaukset: uudet
        });
    };
    const haeVKohteet = async () => {
        const valierat = await getDoc(doc(firestore, 'vaiheet', 'valierat'));
        try {
            const kayttajan = await getDoc(doc(firestore, 'kayttajat', user.uid, 'veikkaukset', 'valierat'));
            setVValinnat(kayttajan.data().veikkaukset ?? {});
        } catch (e) {
            setVValinnat({});
        }
        setVKohteet(valierat.data().kohteet);
    };
    const vaihdaVValinnat = async (kohde, uusi) => {
        const uudet = {
            ...vvalinnat,
            [kohde.otsikko]: uusi
        };
        setVValinnat(uudet);
        await setDoc(doc(firestore, 'kayttajat', user.uid, 'veikkaukset', 'valierat'), {
            veikkaukset: uudet
        });
    };
    const haePKohteet = async () => {
        const puolivalierat = await getDoc(doc(firestore, 'vaiheet', 'puolivalierat'));
        try {
            const kayttajan = await getDoc(doc(firestore, 'kayttajat', user.uid, 'veikkaukset', 'puolivalierat'));
            setPValinnat(kayttajan.data().veikkaukset ?? {});
        } catch (e) {
            setPValinnat({});
        }
        setPKohteet(puolivalierat.data().kohteet);
    };
    const vaihdaPValinnat = async (kohde, uusi) => {
        const uudet = {
            ...pvalinnat,
            [kohde.otsikko]: uusi
        };
        setPValinnat(uudet);
        await setDoc(doc(firestore, 'kayttajat', user.uid, 'veikkaukset', 'puolivalierat'), {
            veikkaukset: uudet
        });
    };
    const hae4Kohteet = async () => {
        const nvaliera = await getDoc(doc(firestore, 'vaiheet', 'neljannesvalierat'));
        try {
            const kayttajan = await getDoc(doc(firestore, 'kayttajat', user.uid, 'veikkaukset', 'neljannesvalierat'));
            setNValinnat(kayttajan.data().veikkaukset ?? {});
        } catch (e) {
            setNValinnat({});
        }
        setNKohteet(nvaliera.data().kohteet);
    };
    const vaihdaNValinnat = async (kohde, uusi) => {
        const uudet = {
            ...nvalinnat,
            [kohde.otsikko]: uusi
        };
        setNValinnat(uudet);
        await setDoc(doc(firestore, 'kayttajat',user.uid,'veikkaukset', 'neljannesvalierat'), {
            veikkaukset: uudet
        });
    };

    const haeKohteet = async () => {
        const lohkot = await getDoc(doc(firestore, 'vaiheet', 'lohko'));
        try {
            const kayttajan = await getDoc(doc(firestore, 'kayttajat', user.uid, 'veikkaukset', 'lohko'));
            setLValinnat(kayttajan.data().veikkaukset ?? {});
        } catch (e) {
            setLValinnat({});
        }
        setUmpeutuminen(lohkot.data().umpeutumisaika);
        setLKohteet(lohkot.data().kohteet);
    };
    const vaihdaValinnat = async (kohde, uusi) => {
        const uudet = {
            ...lvalinnat,
            [kohde.otsikko]: uusi
        };
        setLValinnat(uudet);
        await setDoc(doc(firestore, 'kayttajat', user.uid, 'veikkaukset', 'lohko'), {
            veikkaukset: uudet
        });
    };

    // 3. Efektit
    useEffect(() => {
        if (!user) {
            return;
        }
        haeKohteet();
        hae4Kohteet();
        haePKohteet();
        haeVKohteet();
        haePRKohteet();
        haeFKohteet();
    }, [user]);

    // 4. Ulkoasu
    if (!lkohteet || !nkohteet || !pkohteet || !vkohteet || !prkohteet || !fkohteet) {
        return <CircularProgress />;
    }
    return (
        <DndProvider backend={HTML5Backend}>
            <Box margin='1em'>
            <Grid container>
                {fkohteet.map((kohde) => {
                    return <Kohde
                    key={kohde.otsikko}
                    kohde={kohde}
                    avoinna={kohde.umpeutumisaika.toDate() > new Date()}
                    valinnat={fvalinnat[kohde.otsikko]}
                    onValittu={(uusi) => vaihdaFValinnat(kohde, uusi)}/>;
                })}
            </Grid>
            <Grid container>
                {prkohteet.map((kohde) => {
                    return <Kohde
                    key={kohde.otsikko}
                    kohde={kohde}
                    avoinna={kohde.umpeutumisaika.toDate() > new Date()}
                    valinnat={prvalinnat[kohde.otsikko]}
                    onValittu={(uusi) => vaihdaPRValinnat(kohde, uusi)}/>;
                })}
            </Grid>
            <Grid container>
                {vkohteet.map((kohde) => {
                    return <Kohde
                    key={kohde.otsikko}
                    kohde={kohde}
                    avoinna={kohde.umpeutumisaika.toDate() > new Date()}
                    valinnat={vvalinnat[kohde.otsikko]}
                    onValittu={(uusi) => vaihdaVValinnat(kohde, uusi)}/>;
                })}
            </Grid>
            <Grid container>
                    {pkohteet.map((kohde) => {
                        return <Kohde
                        key={kohde.otsikko}
                        kohde={kohde}
                        avoinna={kohde.umpeutumisaika.toDate() > new Date()}
                        valinnat={pvalinnat[kohde.otsikko]}
                        onValittu={(uusi) => vaihdaPValinnat(kohde, uusi)}/>;
                    })}
                </Grid>
                <Grid container>
                    {nkohteet.map((kohde) => {
                        return <Kohde
                        key={kohde.otsikko}
                        kohde={kohde}
                        avoinna={kohde.paattumisaika.toDate() > new Date()}
                        valinnat={nvalinnat[kohde.otsikko]}
                        onValittu={(uusi) => vaihdaNValinnat(kohde, uusi)}/>;
                    })}
                </Grid>
                <Grid container>
                    {lkohteet.map((kohde) => {
                        return <Kohde
                            key={kohde.otsikko}
                            kohde={kohde}
                            avoinna={umpeutuminen.toDate() > new Date()}
                            valinnat={lvalinnat[kohde.otsikko]}
                            onValittu={(uusi) => vaihdaValinnat(kohde, uusi)} />;
                    })}
                </Grid>

            </Box>
        </DndProvider>
    )
};
export default Veikkaus;