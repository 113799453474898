import { useEffect, useState } from "react";
import { Home, Leaderboard, MoveToInbox, SportsSoccer, TextRotationDownTwoTone } from "@mui/icons-material";
import { BottomNavigation, BottomNavigationAction, Typography, Box, CircularProgress } from "@mui/material";
import Veikkaus from "./Veikkaus";
import Koti from "./Koti";
import Tilastot from "./Tilastot";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { firestore } from "./App";

const HomePage = ({ user }) => {
    const [valittu, setValitse] = useState(0);
    const [tiedot, setTiedot] = useState(null);
    const haeTiedot = async () => {
        try {
            const data = await getDoc(doc(firestore, 'kayttajat', user.uid));
            setTiedot(data.data() ?? {});
        } catch (e) {
            setTiedot({});
        }
    };
    const kirjoitaTiedot = async (uudet) => {
        setTiedot(uudet);
        console.log('uudet', uudet);
        await setDoc(doc(firestore, 'kayttajat', user.uid), uudet);
    }
    useEffect(() => {
        if (user?.uid) {
            haeTiedot();
        }
    }, [user?.uid]);
    /*
    let sivu;
    if (valittu === 0) {
        sivu = <Koti />;
    } 
    if (valittu === 1) {
        sivu = <Tilastot />;
    }
    if (valittu === 2) {
        sivu = <Veikkaus />;
    }*/

    /*
    let sivu;
    switch (valittu) {
        case 0:
            sivu = <Koti />;
            break;
        case 1:
            sivu = <Tilastot />;
            break;
        case 2:
            sivu = <Veikkaus />;
            break;
    }
    */
    if (!user || !tiedot) {
        return <CircularProgress />;
    }
    return (
        <Box className='sivu' flexDirection='column' display='flex'>
            <Box flexGrow={1} style={{ overflowY: 'auto' }}>
                {/*sivu*/}
                {valittu === 0 && <Koti tiedot={tiedot} onMuutos={(uudet) => kirjoitaTiedot(uudet)} />}
                {valittu === 1 && <Tilastot />}
                {valittu === 2 && <Veikkaus user={user} />}
                {/*valittu === 1 ? <Tilastot /> : valittu === 2 ? <Veikkaus /> : <Koti />*/}
            </Box>
            <BottomNavigation
                showLabels value={valittu} onChange={(value, newValue) => {
                    setValitse(newValue);
                }}
            >
                <BottomNavigationAction label="Koti" icon={<Home />} />
                <BottomNavigationAction label="Tilastot" icon={<Leaderboard />} disabled={!tiedot.nimi} />
                <BottomNavigationAction label="Veikkaus" icon={<SportsSoccer />} disabled={!tiedot.nimi} />
            </BottomNavigation>

        </Box>
    );
};
export default HomePage;